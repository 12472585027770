import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import apiurl from "./apiweb.js"
// import FileDownload from 'js-file-download';

const obj = {
    getAllDevices: async (params) =>
    axios({
        'method':'GET',
        'url':apiurl + "device",
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
        'params': {
            ...params
        },
    }),
    getDevice: async (params) =>
    axios({
        'method':'GET',
        'url':apiurl + "device/" + params.device_id,
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
        },
        'crossdomain': true,
    }),
};

export default obj;