import React, { useState } from "react";
import { Form, Button, Col, Row, Table, Container } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import api from '../../api/apiweb-factura.js';

function FacturaFormulario() {
  const initialState = {
    emisor: {
      rut:"",
      razonSocial: "",
      direccion: "",
      comuna: "",
      ciudad: "",
      tipoVenta: "",
      email: "",
      telefono: "",
      giro: "",
    },
    receptor: {
      rut: "",
      razonSocial: "",
      tipoCompra: "",
      direccion: "",
      comuna: "",
      ciudad: "",
      giro: "",
      contacto: "",
      rutSolicita: "",
    },
    transporte: {
      rutTransporte: "",
      patente: "",
      rutChofer: "",
      nombreChofer: "",
    },
    productos: [],
    formaPago: "1",
    referencias: [],
    mostrarReferencias: false,
  };

  const [formData, setFormData] = useState(initialState);

  const [nuevoProducto, setNuevoProducto] = useState({
    nombre: "",
    descripcion: "",
    cantidad: 1,
    unidad: "",
    precio: 0,
    descuento: 0,
  });

  const [visibleSections, setVisibleSections] = useState({
    emisor: true,
    receptor: true,
    transporte: true,
  });

  
   // Manejar cambios en las referencias
   const handleReferenciaChange = (index, field, value) => {
    const nuevasReferencias = [...formData.referencias];
    nuevasReferencias[index] = {
      ...nuevasReferencias[index],
      [field]: value || "", // Asegurar siempre un valor definido
    };
    setFormData((prev) => ({ ...prev, referencias: nuevasReferencias }));
  };

  // Agregar nueva línea de referencia con valores iniciales
  const agregarReferencia = () => {
    setFormData((prev) => ({
      ...prev,
      referencias: [
        ...prev.referencias,
        { tipoDoc: "", folio: "", fecha: "", razon: "" }, 
      ],
    }));
  };

  // Eliminar una referencia
  const eliminarReferencia = (index) => {
    const nuevasReferencias = [...formData.referencias];
    nuevasReferencias.splice(index, 1);
    setFormData((prev) => ({ ...prev, referencias: nuevasReferencias }));
  };

  const toggleSection = (section) => {
    setVisibleSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleInputChange = (e, section, field) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const handleAgregarProducto = () => {
    setFormData((prev) => ({
      ...prev,
      productos: [...prev.productos, { ...nuevoProducto }],
    }));
    setNuevoProducto({
      nombre: "",
      descripcion: "",
      cantidad: 1,
      unidad: "",
      precio: 0,
      descuento: 0,
    });
  };

  const handleFormaPagoChange = (e) => {

    console.log("forma de pago:", e.target.value);
    setFormData((prev) => ({
      ...prev,
      formaPago: e.target.value,
    }));
  };

  const handleLimpiarFormulario = () => {
    setFormData(initialState); // Restablecer el estado del formulario
    setNuevoProducto({
      nombre: "",
      descripcion: "",
      cantidad: 1,
      unidad: "",
      precio: 0,
      descuento: 0,
    }); 
  };

  const handleVisuavilizar = () => {

    const subtotal = formData.productos.reduce((acc, producto) => {
      const cantidad = parseFloat(producto.cantidad || 0);
      const precio = parseFloat(producto.precio || 0);
      return acc + cantidad * precio;
    }, 0);
  
    // Calcular IVA y total
    const tasaIVA = 19; // 19% de IVA
    const iva = (subtotal * tasaIVA) / 100;
    const total = subtotal + iva;
  
    // Generar el JSON
    const jsonGenerado = {
      ID: `MiPE${formData.emisor.rut}-33`, // ID generado dinámicamente
      Encabezado: {
        IdDoc: {
          TipoDTE: "33",
          FchEmis: new Date().toISOString().split("T")[0], 
          TpoTranCompra: "1",
          TpoTranVenta: "1",
          FmaPago: formData.formaPago, 
        },
        Emisor: {
          RUTEmisor: formData.emisor.rut,
          RznSoc: formData.emisor.razonSocial,
          GiroEmis: formData.emisor.giro,
          CorreoEmisor: formData.emisor.email,
          Acteco: "474100", 
          CdgSIISucur: "12345678", //TODO: Revisar
          DirOrigen: formData.emisor.direccion,
          CmnaOrigen: formData.emisor.comuna,
          CiudadOrigen: formData.emisor.ciudad,
        },
        Receptor: {
          RUTRecep: formData.receptor.rut,
          RznSocRecep: formData.receptor.razonSocial,
          GiroRecep: formData.receptor.giro,
          DirRecep: formData.receptor.direccion,
          CmnaRecep: formData.receptor.comuna,
          CiudadRecep: formData.receptor.ciudad,
        },
        Totales: {
          MntNeto: subtotal.toFixed(2), 
          TasaIVA: tasaIVA.toFixed(2),
          IVA: iva.toFixed(2), 
          MntTotal: total.toFixed(2), 
        },
      },
      Detalle: formData.productos.map((producto, index) => ({
        NroLinDet: index + 1,
        NmbItem: producto.nombre,
        DscItem: producto.descripcion,
        QtyItem: parseFloat(producto.cantidad || 0).toFixed(2),
        PrcItem: parseFloat(producto.precio || 0).toFixed(2),
        MontoItem: (
          parseFloat(producto.cantidad || 0) * parseFloat(producto.precio || 0)
        ).toFixed(2),
      })),
      Referencia: formData.referencias.map((referencia, index) => ({
        NroLinRef: index + 1,
        TpoDocRef: referencia.tipoDoc,
        FolioRef: referencia.folio,
        FchRef: referencia.fecha,
        RazonRef: referencia.razon,
      })),
    };
  
  
    // return jsonGenerado;
    console.log(JSON.stringify(jsonGenerado, null, 2));

    api.createFactura(jsonGenerado).then(
      (data) => {
          
      }
    ).catch(
        (error)=>{
        
       }
    )

  }


  return (
    <Container>
      <h2 className="text-center my-4">Factura Electrónica</h2>

      {/* Datos Emisor */}
      <div className="border p-3 mb-4">
        <h5
          onClick={() => toggleSection("emisor")}
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          Datos Emisor
          {visibleSections.emisor ? <FaChevronUp /> : <FaChevronDown />}
        </h5>
        {visibleSections.emisor && (
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Razón Social</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Razón Social"
                  value={formData.emisor.razonSocial}
                  onChange={(e) =>
                    handleInputChange(e, "emisor", "razonSocial")
                  }
                />
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Rut</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Rut"
                  value={formData.emisor.rut}
                  onChange={(e) =>
                    handleInputChange(e, "emisor", "rut")
                  }
                />
              </Form.Group>
            </Col>


            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección"
                  value={formData.emisor.direccion}
                  onChange={(e) => handleInputChange(e, "emisor", "direccion")}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label>Comuna</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Comuna"
                  value={formData.emisor.comuna}
                  onChange={(e) => handleInputChange(e, "emisor", "comuna")}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label>Ciudad</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ciudad"
                  value={formData.emisor.ciudad}
                  onChange={(e) => handleInputChange(e, "emisor", "ciudad")}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Teléfono"
                  value={formData.emisor.telefono}
                  onChange={(e) => handleInputChange(e, "emisor", "telefono")}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={formData.emisor.email}
                  onChange={(e) => handleInputChange(e, "emisor", "email")}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Giro</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Giro"
                  value={formData.emisor.giro}
                  onChange={(e) => handleInputChange(e, "emisor", "giro")}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </div>

      {/* Datos Receptor */}
      <div className="border p-3 mb-4">
        <h5
          onClick={() => toggleSection("receptor")}
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          Datos Receptor
          {visibleSections.receptor ? <FaChevronUp /> : <FaChevronDown />}
        </h5>
        {visibleSections.receptor && (
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>RUT</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="RUT"
                  value={formData.receptor.rut}
                  onChange={(e) => handleInputChange(e, "receptor", "rut")}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Razón Social</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Razón Social"
                  value={formData.receptor.razonSocial}
                  onChange={(e) =>
                    handleInputChange(e, "receptor", "razonSocial")
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección"
                  value={formData.receptor.direccion}
                  onChange={(e) =>
                    handleInputChange(e, "receptor", "direccion")
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Comuna</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Comuna"
                  value={formData.receptor.comuna}
                  onChange={(e) => handleInputChange(e, "receptor", "comuna")}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label>Ciudad</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ciudad"
                  value={formData.receptor.ciudad}
                  onChange={(e) => handleInputChange(e, "receptor", "ciudad")}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label>Contacto</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Contacto"
                  value={formData.receptor.contacto}
                  onChange={(e) => handleInputChange(e, "receptor", "contacto")}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label>RUT Solicita</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="RUT Solicita"
                  value={formData.receptor.rutSolicita}
                  onChange={(e) =>
                    handleInputChange(e, "receptor", "rutSolicita")
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </div>


      {/* Transporte */}
      <div className="border p-3 mb-4">
        <h5
          onClick={() => toggleSection("transporte")}
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          Transporte
          {visibleSections.transporte ? <FaChevronUp /> : <FaChevronDown />}
        </h5>
        {visibleSections.transporte && (
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>RUT Transporte</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="RUT Transporte"
                  value={formData.transporte.rutTransporte}
                  onChange={(e) =>
                    handleInputChange(e, "transporte", "rutTransporte")
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Patente</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Patente"
                  value={formData.transporte.patente}
                  onChange={(e) =>
                    handleInputChange(e, "transporte", "patente")
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </div>

      {/* Productos */}
      <Form className="border p-3 mb-4">
        <h5>Productos</h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th>Unidad</th>
              <th>Precio</th>
              <th>Descuento</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {formData.productos.map((producto, index) => (
              <tr key={index}>
                <td>{producto.nombre}</td>
                <td>{producto.descripcion}</td>
                <td>{producto.cantidad}</td>
                <td>{producto.unidad}</td>
                <td>{producto.precio}</td>
                <td>{producto.descuento}</td>
                <td>{producto.cantidad * producto.precio}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Nombre Producto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                value={nuevoProducto.nombre}
                onChange={(e) =>
                  setNuevoProducto((prev) => ({
                    ...prev,
                    nombre: e.target.value,
                  }))
                }
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Cantidad</Form.Label>
              <Form.Control
                type="number"
                placeholder="Cantidad"
                value={nuevoProducto.cantidad}
                onChange={(e) =>
                  setNuevoProducto((prev) => ({
                    ...prev,
                    cantidad: e.target.value,
                  }))
                }
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Precio</Form.Label>
              <Form.Control
                type="number"
                placeholder="Precio"
                value={nuevoProducto.precio}
                onChange={(e) =>
                  setNuevoProducto((prev) => ({
                    ...prev,
                    precio: e.target.value,
                  }))
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Button className="mt-3" onClick={handleAgregarProducto}>
          Agregar Producto
        </Button>
      </Form>

      {/* Forma de Pago */}
      <Form className="border p-3 mb-4">
        <h5>Forma de Pago</h5>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Seleccionar
          </Form.Label>
          <Col sm={10}>
            <Form.Select
              value={formData.formaPago}
              onChange={handleFormaPagoChange}
            >
              <option value="1">Contado</option>
              <option value="2">Crédito</option>
              <option value="3">Sin Costo</option>
            </Form.Select>
          </Col>
        </Form.Group>
      </Form>

       {/* Referencias */}
       <Form className="border p-3 mb-4">
        <h5>Referencias</h5>
        <Form.Check
          type="checkbox"
          label="Activar referencias"
          checked={formData.mostrarReferencias}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              mostrarReferencias: e.target.checked,
              referencias: e.target.checked ? formData.referencias : [],
            }))
          }
        />
        {formData.mostrarReferencias && (
          <>
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Tipo de Documento</th>
                  <th>Folio Ref.</th>
                  <th>Fecha Ref.</th>
                  <th>Razón Referencia</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {formData.referencias.map((referencia, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        as="select"
                        value={referencia.tipoDoc || ""} // Asegurar que no sea undefined
                        onChange={(e) =>
                          handleReferenciaChange(index, "tipoDoc", e.target.value)
                        }
                      >
                        <option value="">Seleccionar</option>
                        <option value="801">801 - Nota de Crédito</option>
                        <option value="802">802 - Nota de Débito</option>
                        {/* Agrega más opciones aquí */}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Folio"
                        value={referencia.folio || ""} // Asegurar que no sea undefined
                        onChange={(e) =>
                          handleReferenciaChange(index, "folio", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        value={referencia.fecha || ""} // Asegurar que no sea undefined
                        onChange={(e) =>
                          handleReferenciaChange(index, "fecha", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Razón"
                        value={referencia.razon || ""} // Asegurar que no sea undefined
                        onChange={(e) =>
                          handleReferenciaChange(index, "razon", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => eliminarReferencia(index)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="success" onClick={agregarReferencia}>
              Agregar línea de referencia
            </Button>
          </>
        )}
      </Form>
      {/* Botones */}
      <div className="text-end">
        <Button variant="primary" className="me-2" onClick={handleVisuavilizar}>
          Validar y Visualizar
        </Button>
        <Button variant="secondary" className="me-2" onClick={handleLimpiarFormulario}>
          Limpiar
        </Button>
        <Button variant="danger">Guardar Borrador</Button>
      </div>
    </Container>
  );
}

export default FacturaFormulario;
