import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import apiurl from "./apiweb.js"

const obj = {
   
    download: async (params,callback) => {        
            const method = 'GET';
            const url = apiurl + '/skualo'; 
            axios
              .request({
                url,
                method,
                'headers': {
                    'content-type':'application/json',
                    'Authorization':"Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString()
                },        
               crossdomain: true,
                params: { ...params},
              })
              .then(({ data }) => {
                // console.log(data)
                window.location=data;
                if (callback){ 
                  callback(true, '') }
              }).catch(
                err => {callback(false,'Error en la descarga')});
          
    }
};

export default obj;