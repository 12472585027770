import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { Button, Row, Col, Form, Spinner, Modal, Card, Container } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import api from '../../api/apiweb-skualo.js';

import "./Skualo.scss"




function Skualo(props) {

  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return firstDayOfMonth.toISOString().slice(0, 10);
  });

  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [device, setDevice] = useState({ label: "Todos", value: '' });
  const [deviceOptions, setDevicesOptions] = useState([]);
  const [modalDescarga, setModalDescarga] = useState(false);
  const [modalDescargaMensaje, setModalDescargaMensaje] = useState('');
  const [mensajeFallo, setMensajeFallo] = useState(false);



  const handleSearch = () => {


    if (startDate > endDate ) {
      
      setMensajeFallo(true)
      setModalDescargaMensaje("Fecha inicial no puede ser mayor que fecha final");
      setModalDescarga(true)
      return;
    }

    setModalDescarga(true)
    setModalDescargaMensaje('Descargando...')

    const params = {
      startDate, endDate
    }

    if(device.value!==''){
      params.device_id = device.value;
    }

    console.log(params);
    // return;
    api.download(params, (state, message) => {
      if (state) {
        setModalDescarga(false)
        setModalDescargaMensaje('')
      }
      else {
        if (message === ('Error en la descarga')) {
          setModalDescargaMensaje(message)
          setMensajeFallo(true)
        } else {
          setModalDescargaMensaje(message)
        }
        setModalDescarga(true)

      }
    });

  };

  useEffect(() => {
    const devices = props.devices;
    const array = [{ label: 'Todos', value: '' }]
    // console.log(devices)
    if (Array.isArray(devices)) {
      devices.forEach(element => {
        // console.log(element.name)
        const label = element.name;
        const value = element.device_id;
        array.push({ label, value });

      })
      // console.log(array);

      setDevicesOptions(array)
    }


  }, [props.devices]);


  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card>
            <Card.Header>
              <h4 className="text-center">Buscar Documento</h4>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row className="mb-3">
                  <Col>
                    <Form.Label>Fecha Inicio</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Fecha Final</Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Label>Device ID</Form.Label>
                    <Select
                      options={deviceOptions}
                      value={device}
                      onChange={(e) => setDevice(e)}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  <Button variant="primary" onClick={handleSearch}>
                    Buscar y Descargar <FaDownload className="ms-2" />
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal de Descarga */}
      <Modal show={modalDescarga} centered>
        <Modal.Header>
          <Modal.Title>Descarga documentos</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {modalDescargaMensaje}
          {!mensajeFallo && <Spinner animation="border" role="status" className="mt-3" />}
        </Modal.Body>
        <Modal.Footer>
          {mensajeFallo && (
            <Button variant="secondary" onClick={() => setModalDescarga(false)}>
              Cerrar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};


export default Skualo;
