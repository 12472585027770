import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios';
import apiurl from "./apiweb.js"


const obj = {
   
    createFactura: async (params) => {

        console.log(params);
        // return;
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
        return axios.post(apiurl + "submitjsonfac",
            { 
                ...params 
            }
            ,
             {
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            crossdomain: true
        });
    },
   
};

export default obj;